/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/bootstrap.css"
import "./src/styles/global.sass"



export const onClientEntry = () => {
  window.addEventListener('load', function () {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    let vh = document.documentElement.clientHeight / 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  window.addEventListener('resize', function() {
    let vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    let vh = document.documentElement.clientHeight / 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  if (!document || !window) {
    return;
  }
  var styleText = '::-moz-focus-inner{border:0 !important;}:focus{outline: none !important;';
  var unfocus_style = document.createElement('STYLE');

  window.unfocus = function () {
    document.getElementsByTagName('HEAD')[0].appendChild(unfocus_style);
    document.addEventListener('mousedown', function () {
      unfocus_style.innerHTML = styleText + '}';
    });
    document.addEventListener('keydown', function () {
      unfocus_style.innerHTML = '';
    });
  };

  window.unfocus.style = function (style) {
    styleText += style;
  };

  window.unfocus();
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // if (!(`IntersectionObserver` in window)) {
  //   import(`intersection-observer`)
  //   console.log(`# IntersectionObserver is polyfilled!`)
  // }
}
